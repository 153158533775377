@import '../../../style/palette.scss';

.textarea--edit {
  padding: 17px 17px 17px 18px;
  width: 100%;
  height: 115px;
  background: rgba(37, 186, 70, 0.1);
  border: 2px solid rgba(37, 186, 70, 0.1);
  border-radius: 14px;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.37;
  color: $grayscale900;
  resize: none;
  &::placeholder {
    color: $grayscale900;
    opacity: 0.6;
  }
}
