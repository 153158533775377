@import '../../../../style/palette.scss';

.launchpools-card {
  background: $white;
  border-radius: 16px;
  padding: 24px 28px;
  overflow: hidden;
  position: relative;
  &_label {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 78px;
    padding: 10px 15px;
    min-height: 32px;
    background: linear-gradient(138.44deg, $primary -5.34%, rgba(37, 186, 70, 0.1) 133.63%);
    border-bottom-left-radius: 20px;
    display: grid;
    place-items: center;
    color: $white;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.2;
  }
  &_header {
    padding-top: 4px;
    padding-bottom: 16px;
    margin-bottom: 12px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    column-gap: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.38;
    position: relative;
    &::after {
      content: '';
      width: calc(100% + 56px);
      height: 1px;
      background: #cdcdcd;
      position: absolute;
      left: -28px;
      bottom: 0;
    }
  }
  &_logo {
    position: relative;
  }
  &_sublogo {
    position: absolute;
    bottom: -6px;
    right: -6px;
  }
  &_title {
    color: $text;
  }
  &_descr {
    margin-top: 5px;
    font-weight: 600;
    font-size: 11px;
    line-height: 1.28;
    color: #989898;
  }
  &_percent {
    margin-top: 10px;
    color: $primaryBright;
    display: grid;
    grid-auto-flow: column;
    column-gap: 4px;
    align-items: center;
    span {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        border-bottom: dashed 1px;
      }
    }
  }
  &_btn {
    padding: 19px;
    width: 100%;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.38;
    color: $white;
    margin-bottom: 12px;
    transition: opacity 250ms;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
  &_info {
    min-height: 59px;
    display: grid;
    align-content: center;
    margin-bottom: 12px;
    &.available {
      color: $text;
      row-gap: 8px;
    }
    &_title {
      font-size: 14px;
      line-height: 1.15;
      font-weight: 500;
    }
    &_note {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.34;
      border-bottom: dashed 1px;
      width: fit-content;
    }
    &.earned {
      grid-auto-flow: column;
      justify-content: space-between;
      font-weight: 600;
      font-size: 10px;
      line-height: 1.2;
      letter-spacing: 0.02em;
    }
    &_earned {
      color: #989898;
    }
    &_label {
      margin-top: 15px;
      background: #989898;
      border-radius: 49px;
      padding: 10px 9px;
      width: fit-content;
      color: $white;
    }
    &_btn {
      background: #d8e7db;
      border-radius: 16px;
      padding: 18.5px;
      min-width: 136px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.38;
      color: $white;
    }
  }
  &_toggler {
    background-color: transparent;
    margin: 12px auto 0;
    border: none;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    column-gap: 4px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.38;
    color: $primaryBright;
    cursor: pointer;
    transition: opacity 250ms;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
    &_arrow {
      display: grid;
      align-items: center;
      &.open {
        transform: rotate(180deg);
        transform: scale(1, -1);
      }
    }
  }
  &_content {
    padding: 11px 0 4px;
  }
  &_list {
    display: grid;
    row-gap: 10px;
    &_item {
      color: #989898;
      font-weight: 600;
      position: relative;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: end;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M-8 1L146 1.00001' stroke='%23CDCDCD' stroke-linecap='round' stroke-dasharray='4 4'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='8' height='2' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        overflow: hidden;
        border-radius: 8px;
      }
    }
    &_name,
    &_value {
      padding-bottom: 2px;
      background: $white;
      position: relative;
      z-index: 2;
    }
    &_name {
      font-size: 10px;
      line-height: 1.2;
      letter-spacing: 0.02em;
      padding-right: 4px;
    }
    &_value {
      font-size: 12px;
      line-height: 1.17;
      padding-left: 4px;
      &.total {
        color: $text;
      }
    }
  }
  &_links {
    margin-top: 16px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
  &_link {
    color: $primaryBright;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.34;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    column-gap: 5px;
  }
}
